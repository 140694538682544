import * as React from 'react'
import IndexLayout from '../layouts'
import Location from "../components/location/Location";
import HeaderDesktop from "../components/HeaderDesktop";
import Helmet from "react-helmet";

const LocationPage = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Helmet

      title={'Vilhelm7 Berlin Residences | Lage'}
      meta={[
        { name: 'description', content: 'Vilhelm7 V7 Apartments Hotel Zimmer Berlin billig preiswert günstig buchen Übernachtung' },
        { name: 'keywords', content: 'Vilhelm7, V7, Apartments, buchen, Hotel, Zimmer, Berlin, Übernachtung'},
        {name: 'page-type', content: 'Hotel, Apartments'},
        { name:'copyright', content:"https://www.vilhelm7.de"},
        { name:'X-UA-Compatible', content:"IE=edge"},


      ]}

    />

    <HeaderDesktop></HeaderDesktop>
    <Location></Location>
  </IndexLayout>
)

export default LocationPage
